<script lang="ts">
    import { getContext, onMount } from 'svelte';
    import toast from 'svelte-french-toast';
    import ImageIcon from '../../Icons/ImageIcon.svelte';
    import CloseIcon from '../../Icons/CloseIcon.svelte';

    import * as Alert from '$lib/components/ui/alert';
    import * as Dialog from '$lib/components/ui/dialog/index.js';

    import Label from '$lib/components/ui/label/label.svelte';
    import Input from '$lib/components/ui/input/input.svelte';
    import { Textarea } from '$lib/components/ui/textarea';
    import Button from '$lib/components/ui/button/button.svelte';

    import axios from 'axios';
    import * as Select from '$lib/components/ui/select';
    import * as Popover from '$lib/components/ui/popover';
    import { cn } from '$lib/utils';
    import ResendVerificationEmailBox from '../../Components/ResendVerificationEmailBox.svelte';

    import { Calendar as CalendarPrimitive } from 'bits-ui';
    import {
        DateFormatter,
        getLocalTimeZone,
        today,
        type DateValue,
        parseDate,
        CalendarDate,
    } from '@internationalized/date';
    import { CalendarIcon } from 'lucide-svelte';
    import * as Calendar from '$lib/components/ui/calendar/index.js';
    import type Dropzone from 'dropzone';
    import type { DOMElement } from 'bits-ui/dist/internal';
    import type { Gender } from '@/interfaces/general';
    import type { SettingsContext } from '@/interfaces/settings';

    export let minDate: DateValue | undefined = undefined;

    const dateFormatter = new DateFormatter('en-US', { dateStyle: 'long' });
    const maxDate = today(getLocalTimeZone()).subtract({ years: 18 });

    const isDateDisabled = (date: DateValue) => date instanceof CalendarDate && date > maxDate;
    let mediaSettings = '';
    const monthOptions = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ].map((month, i) => ({ value: i + 1, label: month }));

    const currentYear = new Date().getFullYear();
    const yearOptions = Array.from({ length: 100 }, (_, i) => ({
        label: String(currentYear - i),
        value: currentYear - i,
    })).filter((option) => option.value <= maxDate.year);

    $: placeholder = birthdate || maxDate;

    $: defaultYear = {
        value: placeholder.year,
        label: String(placeholder.year),
    };

    $: defaultMonth = {
        value: placeholder.month,
        label: monthOptions[placeholder.month - 1].label,
    };

    $: availableMonths = monthOptions.filter((month) => {
        if (placeholder.year < maxDate.year) return true;
        return month.value <= maxDate.month;
    });

    function updateBirthdate(newDate: DateValue) {
        if (newDate <= maxDate) {
            birthdate = newDate;
        } else {
            birthdate = newDate.set({ year: maxDate.year });
        }
    }

    axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
    const csrfTokenMeta = document.querySelector('meta[name="csrf-token"]');
    if (csrfTokenMeta) {
        axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfTokenMeta.getAttribute('content');
    }

    const settingsContext = getContext<SettingsContext>('settingsContext');
    const { authUser, data } = settingsContext;

    let currentGender: Gender | null = data.genders.find((g) => g.id === authUser.gender_id) || null;

    let selectedGender = currentGender ? { value: currentGender.id, label: currentGender.gender_name } : null;

    let successMessage = '';

    let birthdate: DateValue | undefined = authUser.birthdate ? parseDate(authUser.birthdate) : undefined;

    let myDropzone: { [key: string]: Dropzone } = {};

    const initUploader = (type: string) => {
        let selector = '';
        selector = '.profile-cover-bg';
        if (type === 'avatar') {
            selector = '.avatar-holder';
        }
        myDropzone[type] = new window.Dropzone(selector, {
            url: app.baseUrl + '/my/settings/profile/upload/' + type,
            paramName: 'file', // The name that will be used to transfer the file
            headers: {
                'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]')?.getAttribute('content') || '',
            },
            clickable: [`${selector} .upload-button`],
            maxFilesize: mediaSettings?.max_file_upload_size, // MB
            addRemoveLinks: true,
            dictRemoveFile: 'x',
            acceptedFiles: mediaSettings?.allowed_file_extensions,
            autoDiscover: false,
            sending: function (file) {
                file.previewElement.innerHTML = '';
            },
            success: function (file: Dropzone.DropzoneFile, response) {
                const cardImgTop = document.querySelector(selector + ' .card-img-top') as HTMLImageElement;
                if (cardImgTop) {
                    cardImgTop.src = response.assetSrc;
                }
                if (type === 'avatar') {
                    const userAvatar = document.querySelector('.user-avatar') as HTMLImageElement;
                    if (userAvatar) {
                        userAvatar.src = response.assetSrc;
                    }
                }
                file.previewElement.innerHTML = '';
            },
            error: function (file, errorMessage) {
                if (typeof errorMessage === 'string') {
                    toast.error(errorMessage);
                } else {
                    toast.error(errorMessage.errors.file);
                }
                file.previewElement.innerHTML = '';
            },
        });
    };

    onMount(() => {
        // Function to toggle the visibility of actions holder
        function toggleActionsHolder(element: HTMLElement) {
            const actionsHolder = element.querySelector('.actions-holder');
            if (actionsHolder) {
                actionsHolder.classList.toggle('d-none');
            }
        }

        // Function to handle mouseenter event
        function handleMouseEnter(element: HTMLElement) {
            const actionsHolder = element.querySelector('.actions-holder');
            if (actionsHolder) {
                actionsHolder.classList.remove('d-none');
            }
        }

        // Function to handle mouseleave event
        function handleMouseLeave(element: HTMLElement) {
            const actionsHolder = element.querySelector('.actions-holder');
            if (actionsHolder) {
                actionsHolder.classList.add('d-none');
            }
        }

        // Add event listeners to .avatar-holder and .profile-cover-bg elements
        const avatarHolder = document.querySelector('.avatar-holder');
        const profileCoverBg = document.querySelector('.profile-cover-bg');

        if (avatarHolder) {
            avatarHolder.addEventListener('click', function (e) {
                e.preventDefault();
                toggleActionsHolder(this);
            });

            avatarHolder.addEventListener('mouseenter', function () {
                handleMouseEnter(this);
            });

            avatarHolder.addEventListener('mouseleave', function () {
                handleMouseLeave(this);
            });
        }

        if (profileCoverBg) {
            profileCoverBg.addEventListener('click', function (e) {
                e.preventDefault();
                toggleActionsHolder(this);
            });

            profileCoverBg.addEventListener('mouseenter', function () {
                handleMouseEnter(this);
            });

            profileCoverBg.addEventListener('mouseleave', function () {
                handleMouseLeave(this);
            });
        }

        initUploader('cover');
        initUploader('avatar');
    });

    function updateProfile(event: Event) {
        event.preventDefault();
        const spinner = document.querySelector('#spinner') as HTMLElement;
        spinner.style.display = 'inline-block';

        const submitButton = document.querySelector('#submissionBtn') as HTMLButtonElement;
        const buttonText = submitButton.querySelector('.buttonText') as HTMLElement;
        buttonText.style.display = 'none';

        const formData = new FormData(event.target as HTMLFormElement);
        const dataToSend = Object.fromEntries(formData.entries());
        dataToSend.gender = selectedGender ? selectedGender.value.toString() : '';
        dataToSend.birthdate = birthdate ? birthdate.toString() : '';

        axios
            .post('/my/settings/profile/save', dataToSend)
            .then((response) => {
                submitButton.disabled = false;
                spinner.style.display = 'none';
                buttonText.style.display = 'inline-block';
                successMessage = response.data.message;
                toast.success(response.data.message);
            })
            .catch((error) => {
                if (error.response && error.response.status === 422) {
                    toast.error(error.response.data.message);
                } else {
                    toast.error(error.response.data.message);
                }
                submitButton.disabled = false;
                spinner.style.display = 'none';
                buttonText.style.display = 'inline-block';
            });
    }

    let showAvatarDialog = false;
    let showCoverDialog = false;

    function confirmDeleteAvatar() {
        deleteAvatar();
        showAvatarDialog = false;
    }

    function confirmDeleteCover() {
        deleteCover();
        showCoverDialog = false;
    }

    function deleteCover() {
        axios
            .post('/my/settings/profile/remove/cover')
            .then(function (response) {
                // On success
                toast.success(response.data.message);
                document.querySelector('.profile-cover-bg img')?.setAttribute('src', response.data.data.cover);
                document.querySelector('.avatar-holder img')?.setAttribute('src', response.data.data.avatar);
            })
            .catch(function (error) {
                // On error
                console.warn(error);
            });
    }

    function deleteAvatar() {
        axios
            .post('/my/settings/profile/remove/avatar')
            .then(function (response) {
                // On success
                toast.success(response.data.message);
                document.querySelector('.profile-cover-bg img')?.setAttribute('src', response.data.data.cover);
                document.querySelector('.avatar-holder img')?.setAttribute('src', response.data.data.avatar);
            })
            .catch(function (error) {
                // On error
                console.warn(error);
            });
    }
    const defaultDisplayDate = parseDate('2006-08-01');
    document.addEventListener('DOMContentLoaded', (event) => {
        // Get the button and modal elements
        const openModalButton = document.querySelector('.aiModalBtn') as HTMLElement;
        const myModal = new bootstrap.Modal(document.getElementById('suggest-description-dialog'));

        // Add click event listener to the button
        openModalButton.addEventListener('click', () => {
            myModal.show();
        });
    });
</script>

<svelte:head>
    <link href=" https://cdn.jsdelivr.net/npm/air-datepicker@3.4.0/air-datepicker.min.css" rel="stylesheet" />
</svelte:head>

{#if authUser.email_verified_at === null}
    <svelte:component this="{ResendVerificationEmailBox}" />
{/if}
<form on:submit="{updateProfile}" class="space-y-4">
    <div class="mb-4">
        <div class="">
            <div class="card profile-cover-bg">
                <img class="card-img-top centered-and-cropped" src="{authUser['cover']}" alt="cover" />
                <div class="card-img-overlay d-flex justify-content-center align-items-center">
                    <div class="actions-holder d-none">
                        <div class="d-flex">
                            <span
                                class="h-pill h-pill-accent pointer-cursor upload-button mr-1"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Upload cover image"
                            >
                                <ImageIcon />
                            </span>
                            <Dialog.Root bind:open="{showCoverDialog}">
                                <Dialog.Trigger>
                                    <span
                                        class="h-pill h-pill-accent pointer-cursor"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="Remove cover image"
                                    >
                                        <svelte:component this="{CloseIcon}" />
                                    </span>
                                </Dialog.Trigger>
                                <Dialog.Content class="sm:max-w-[425px]">
                                    <Dialog.Header>
                                        <Dialog.Title>Delete Cover Image</Dialog.Title>
                                        <Dialog.Description>
                                            Are you sure you want to delete your cover image?
                                        </Dialog.Description>
                                    </Dialog.Header>
                                    <Dialog.Footer>
                                        <Button on:click="{() => (showCoverDialog = false)}" variant="ghost">
                                            Cancel
                                        </Button>
                                        <Button variant="destructive" on:click="{confirmDeleteCover}">Delete</Button>
                                    </Dialog.Footer>
                                </Dialog.Content>
                            </Dialog.Root>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="card avatar-holder">
                <img class="card-img-top" src="{authUser['avatar']}" alt="avatar" />
                <div class="card-img-overlay d-flex justify-content-center align-items-center">
                    <div class="actions-holder d-none">
                        <div class="d-flex">
                            <span
                                class="h-pill h-pill-accent pointer-cursor upload-button mr-1"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Upload avatar"
                            >
                                <ImageIcon />
                            </span>
                            <Dialog.Root bind:open="{showAvatarDialog}">
                                <Dialog.Trigger class="">
                                    <span
                                        class="h-pill h-pill-accent pointer-cursor"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="Remove cover image"
                                    >
                                        <svelte:component this="{CloseIcon}" />
                                    </span>
                                </Dialog.Trigger>
                                <Dialog.Content class="sm:max-w-[425px]">
                                    <Dialog.Header>
                                        <Dialog.Title>Delete Avatar</Dialog.Title>
                                        <Dialog.Description>
                                            Are you sure you want to delete your avatar?
                                        </Dialog.Description>
                                    </Dialog.Header>
                                    <Dialog.Footer>
                                        <Button on:click="{() => (showAvatarDialog = false)}" variant="ghost"
                                            >Cancel</Button
                                        >
                                        <Button variant="destructive" on:click="{confirmDeleteAvatar}">Delete</Button>
                                    </Dialog.Footer>
                                </Dialog.Content>
                            </Dialog.Root>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {#if successMessage}
        <Alert.Root variant="success" class="mt-2">
            <Alert.Description>{successMessage}</Alert.Description>
        </Alert.Root>
    {/if}

    <div class="space-y-2">
        <Label for="username">Username</Label>
        <Input id="username" name="username" value="{authUser.username}" />
    </div>

    <div class="space-y-2">
        <Label for="name">Display Name</Label>
        <Input id="name" name="name" value="{authUser.name}" />
    </div>

    <div class="space-y-2">
        <Label for="bio">Bio</Label>
        <Textarea id="bio" name="bio" rows="3" value="{authUser.bio}" />
    </div>

    <div class="space-y-2">
        <Label for="birthdate">Birthdate</Label>
        <Popover.Root>
            <Popover.Trigger asChild let:builder>
                <Button
                    variant="outline"
                    class="{cn('w-full justify-start text-left font-normal', !birthdate && 'text-muted-foreground')}"
                    builders="{[builder]}"
                >
                    <CalendarIcon class="mr-2 h-4 w-4" />
                    {birthdate ? dateFormatter.format(birthdate.toDate(getLocalTimeZone())) : 'Pick a date'}
                </Button>
            </Popover.Trigger>
            <Popover.Content class="w-auto p-0">
                <CalendarPrimitive.Root
                    bind:value="{birthdate}"
                    placeholder="{maxDate}"
                    {isDateDisabled}
                    class="rounded-md border p-3"
                    let:months
                    let:weekdays
                >
                    <Calendar.Header>
                        <Calendar.Heading class="flex w-full items-center justify-between gap-2">
                            <Select.Root
                                selected="{defaultMonth}"
                                items="{availableMonths}"
                                onSelectedChange="{(v) => {
                                    if (!v || !placeholder) return;
                                    if (v.value === placeholder?.month) return;
                                    updateBirthdate(placeholder.set({ month: v.value }));
                                }}"
                            >
                                <Select.Trigger aria-label="Select month" class="w-[60%]">
                                    <Select.Value placeholder="Select month" />
                                </Select.Trigger>
                                <Select.Content class="calendar-select-content">
                                    {#each availableMonths as { value, label }}
                                        <Select.Item {value} {label}>
                                            {label}
                                        </Select.Item>
                                    {/each}
                                </Select.Content>
                            </Select.Root>
                            <Select.Root
                                selected="{defaultYear}"
                                items="{yearOptions}"
                                onSelectedChange="{(v) => {
                                    if (!v || !placeholder) return;
                                    if (v.value === placeholder?.year) return;
                                    updateBirthdate(placeholder.set({ year: v.value }));
                                }}"
                            >
                                <Select.Trigger aria-label="Select year" class="w-[40%]">
                                    <Select.Value placeholder="Select year" />
                                </Select.Trigger>
                                <Select.Content class="calendar-select-content">
                                    {#each yearOptions as { value, label }}
                                        <Select.Item {value} {label}>
                                            {label}
                                        </Select.Item>
                                    {/each}
                                </Select.Content>
                            </Select.Root>
                        </Calendar.Heading>
                    </Calendar.Header>
                    <Calendar.Months>
                        {#each months as month}
                            <Calendar.Grid>
                                <Calendar.GridHead>
                                    <Calendar.GridRow class="flex">
                                        {#each weekdays as weekday}
                                            <Calendar.HeadCell>
                                                {weekday.slice(0, 2)}
                                            </Calendar.HeadCell>
                                        {/each}
                                    </Calendar.GridRow>
                                </Calendar.GridHead>
                                <Calendar.GridBody>
                                    {#each month.weeks as weekDates}
                                        <Calendar.GridRow class="mt-2 w-full">
                                            {#each weekDates as date}
                                                <Calendar.Cell {date}>
                                                    <Calendar.Day {date} month="{month.value}" />
                                                </Calendar.Cell>
                                            {/each}
                                        </Calendar.GridRow>
                                    {/each}
                                </Calendar.GridBody>
                            </Calendar.Grid>
                        {/each}
                    </Calendar.Months>
                </CalendarPrimitive.Root>
            </Popover.Content>
        </Popover.Root>
    </div>

    <div class="flex flex-col sm:flex-row sm:space-x-4">
        <div class="flex-1 space-y-2">
            <Label for="gender">Gender</Label>
            <Select.Root bind:selected="{selectedGender}">
                <Select.Trigger class="w-full">
                    <Select.Value placeholder="Select gender" />
                </Select.Trigger>
                <Select.Content>
                    {#each data.genders as gender}
                        <Select.Item value="{gender.id}" label="{gender.gender_name}"></Select.Item>
                    {/each}
                </Select.Content>
            </Select.Root>
        </div>
        {#if data.allow_gender_pronouns}
            <div class="flex-1 space-y-2">
                <Label for="pronoun">Gender pronoun</Label>
                <Input id="pronoun" name="pronoun" value="{authUser.gender_pronoun}" />
            </div>
        {/if}
    </div>

    <div class="space-y-2">
        <Label for="location">Location</Label>
        <Input id="location" name="location" value="{authUser.location}" />
    </div>

    <div class="space-y-2">
        <Label for="website">Website URL</Label>
        <Input type="url" id="website" name="website" value="{authUser.website}" />
    </div>

    <div class="my-4">
        <Button class="w-full text-white" id="submissionBtn" type="submit">
            <span id="spinner" class="" style="display: none">
                <svg class="mr-3 h-5 w-5 animate-spin" viewBox="0 0 24 24">
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path
                        class="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                </svg>
            </span>
            <span class="buttonText">Save</span>
        </Button>
    </div>
</form>

<style>
    /* These styles will be applied globally */
    :global(.calendar-select-content) {
        max-height: 200px !important;
        overflow-y: auto !important;
    }

    :global(.calendar-select-content .select-viewport) {
        padding: 5px;
    }

    :global(.calendar-select-item) {
        padding: 5px;
        cursor: pointer;
    }

    :global(.calendar-select-item:hover) {
        background-color: #f0f0f0;
    }
</style>
